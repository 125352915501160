'use client'; // Error components must be Client Components

import errorReporter from '@/actions/error-reporter';
import { useEffect } from 'react';

export default function Error({ error }: { error: Error }) {
  useEffect(() => {
    async function sendError() {
      await errorReporter(error.stack);
    }

    sendError();
  }, [error]);

  return (
    <div className="h-96 flex flex-col justify-center text-center gap-5 px-5">
      <p>Sorry, something went wrong.</p>

      <p>Please refresh the page and try again.</p>
    </div>
  );
}
